import React from 'react';
import { makeStyles, Button, tokens, shorthands } from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as LucideIcons from 'lucide-react';

interface SavedPromptData {
    id: string;
    title: string;
    shortDescription: string;
    longDescription: string;
    iconText: string;
    createdBy: string;
    // Add other fields as needed
}

interface SavedPromptStartProps {
    promptData: SavedPromptData | null;
    onStartChat: (promptId: string) => void;
    onCancel: () => void;
    mode: 'modal' | 'splash';
}

const useStyles = makeStyles({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modal: {
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        maxWidth: '500px',
        height: '500px',
        width: '90%',
        boxShadow: tokens.shadow64,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    splash: {
        backgroundColor: 'transparent',
        maxWidth: '500px',
        height: 'auto',
        width: '90%',
        boxShadow: 'none',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin('0', 'auto'), // Center the splash mode
        marginTop: '100px',
    },
    closeButton: {
        position: 'absolute',
        top: tokens.spacingVerticalXS,
        right: tokens.spacingHorizontalXS,
        backgroundColor: 'transparent',
        ...shorthands.border('none'),
        cursor: 'pointer',
        color: tokens.colorNeutralForeground1,
        ...shorthands.padding('0'),
        minWidth: 'auto', 
        height: 'auto', 
    },
    closeIcon: {
        fontSize: tokens.fontSizeBase500,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        ...shorthands.padding(tokens.spacingVerticalXXL),
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column', // Change to column to stack icon and title
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: tokens.spacingVerticalS,
    },
    icon: {
        fontSize: '24pt',
        marginBottom: tokens.spacingVerticalS, // Add margin below the icon
        marginTop: 'tokens.spacingVerticalM',
    },
    title: {
        fontSize: tokens.fontSizeBase600,
        fontWeight: tokens.fontWeightSemibold,
        justifyContent: 'center',
        textAlign: 'center',
    },
    creator: {
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorNeutralForeground3,
        marginBottom: tokens.spacingVerticalL,
        textAlign: 'center',
    },
    descriptionContainer: {
        marginTop: tokens.spacingVerticalM,
    },
    description: {
        textAlign: 'justify',
    },
    buttonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 'auto',
        paddingTop: tokens.spacingVerticalL,
    },
});

export const SavedPromptStart: React.FC<SavedPromptStartProps> = ({ promptData, onStartChat, onCancel, mode }) => {
    const classes = useStyles();

    if (!promptData) {
        return null; // or some loading indicator
    }

    const getIconComponent = (iconName: string): React.ElementType => {
        // Convert dash-separated name to PascalCase
        const pascalCaseName = iconName.split('-')
            .map(part => part.charAt(0).toUpperCase() + part.slice(1))
            .join('');
    
        // Check if the icon exists in LucideIcons
        if (pascalCaseName in LucideIcons) {
            return (LucideIcons as any)[pascalCaseName];
        }
    
        // If not found, return default icon
        //console.log(`Icon not found for: ${iconName}. Using default FileIcon.`);
        return LucideIcons.FileIcon;
    };   

    return (
        <div className={mode === 'modal' ? classes.overlay : ''}>
            <div className={mode === 'modal' ? classes.modal : classes.splash}>
                {mode === 'modal' && (
                    <Button
                        className={classes.closeButton}
                        onClick={onCancel}
                        icon={<Dismiss24Regular className={classes.closeIcon} />}
                    />
                )}
                <div className={classes.content}>
                    <div className={classes.titleContainer}>
                        <span className={classes.icon}>{React.createElement(getIconComponent(promptData.iconText), { size: 40 })}</span>
                        <h2 className={classes.title}>{promptData.title}</h2>
                    </div>
                    <p className={classes.creator}>Created by: {promptData.createdBy}</p>
                    <div className={classes.descriptionContainer}>
                        <p className={classes.description}>{promptData.longDescription}</p>
                    </div>
                    {mode === 'modal' && (
                        <div className={classes.buttonContainer}>
                            <Button appearance="primary" onClick={() => onStartChat(promptData.id)}>
                                Start Chat
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};