import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Dismiss16Regular } from '@fluentui/react-icons';
import { useAppSelector, useAppDispatch  } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { setFileViewerPath, setShowFileViewer } from '../..//redux/features/app/appSlice';
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

//alert(pdfjs.version);
//pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `js/pdf.worker.min.js`;

interface PDFViewerProps {
  onClose: () => void;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ onClose }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  //const [hidden, setHidden] = useState(false); // State for hiding/showing the component
  const { fileViewerPath, showFileViewer } = useAppSelector((state: RootState) => state.app);
  const dispatch = useAppDispatch();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  }

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = fileViewerPath;
    link.download = 'downloaded.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleOnClose = () => {
    dispatch(setFileViewerPath('')); // Set fileViewerPath to null
    dispatch(setShowFileViewer(false)); // Set showFileViewer to false

    // setHidden(true); // Set the component to be hidden
    onClose(); // Call the onClose function
  }

  // if (hidden) {
  //   return null; // Return null to hide the component
  // }
  if(fileViewerPath == '') {
    return null;
  }

  function htmlDecode(input:string) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  //alert(htmlDecode(fileViewerPath));

  return (<>
  {/* showFileViewer:{showFileViewer}<br/>
  fileViewerPath:{fileViewerPath}<br/> */}
    {showFileViewer && (
      <div style={{ width: '60%', margin: 'auto', border: '0px solid #ccc', borderRadius: '0px', padding: '16px', overflowY: 'scroll', height: '95%' }}>
        {/* <button
          style={{
            // position: 'absolute',
            // top: '90px',
            // right: '10px',
            padding: '3px',
            background: '#57504d', 
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '8pt',
            width: '20px',
            float: 'right'
          }}
          onClick={onClose} // Use the onClose function for the close button
        >
          X
        </button>       */}
          <Dismiss16Regular
              aria-label="dismiss message"
              onClick={handleOnClose}
              color="black"
              style={{position:'absolute', right: '20px', cursor: 'pointer'}}

          />      
          <button
            style={{ padding: '5px 10px', background: '#57504d', color: 'white', border: 'none', borderRadius: '5px',
            cursor: 'pointer',
            marginBottom: '0px',
            fontSize: '8pt'
            
          }}
            onClick={handleDownload}>
            Download
        </button>
        <Document
          // file="/test.pdf"
          file={htmlDecode(fileViewerPath)}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages || 0), (_, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </div>)}
  </>);
};

export default PDFViewer;
