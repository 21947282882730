import React, { useState, useMemo } from 'react';
import * as LucideIcons from 'lucide-react';

type IconOption = {
  value: string;
  icon: React.ElementType;
  label: string;
};

interface IconPickerProps {
  value: string;
  onChange: (value: string) => void;
}

const IconPicker: React.FC<IconPickerProps> = ({ value, onChange }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const iconsPerPage = 54;

  const iconOptions: IconOption[] = useMemo(() => {
    const icons = Object.entries(LucideIcons)
      .filter(([name]) => name !== 'createLucideIcon' && name !== 'default')
      .map(([name, Icon]) => ({
        value: name.replace(/([A-Z])/g, '-$1').toLowerCase().replace(/^-/, ''),
        icon: Icon as React.ElementType,
        label: name.replace(/([A-Z])/g, ' $1').trim(),
      }));

    // Filter out icons with redundant "-icon" suffix
    const uniqueIcons = icons.reduce((acc, current) => {
      const baseIconName = current.value.replace(/-icon$/, '');
      const existingIcon = acc.find(item => item.value === baseIconName);
      
      if (!existingIcon) {
        // If there's no existing icon with the base name, add the current icon
        return [...acc, current];
      } else if (current.value === baseIconName) {
        // If the current icon is the base version, replace the "-icon" version
        return acc.map(item => item.value === `${baseIconName}-icon` ? current : item);
      } else {
        // If it's the "-icon" version and we already have the base version, skip it
        return acc;
      }
    }, [] as IconOption[]);

    return uniqueIcons;
  }, []);
  
  const filteredIcons = useMemo(() => {
    return iconOptions.filter(icon => 
      icon.label.toLowerCase().includes(search.toLowerCase())
    );
  }, [iconOptions, search]);

  const totalPages = Math.ceil(filteredIcons.length / iconsPerPage);
  const paginatedIcons = filteredIcons.slice(
    (currentPage - 1) * iconsPerPage,
    currentPage * iconsPerPage
  );

  const selectedIcon = iconOptions.find(icon => icon.value === value) || iconOptions[0];
  const SelectedIconComponent = selectedIcon.icon;

  const handleIconSelect = (newValue: string) => {
    onChange(newValue);
    setIsExpanded(false);
  };

  const handlePrevPage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevent form submission
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const handleNextPage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevent form submission
    setCurrentPage(prev => Math.min(prev + 1, totalPages));
  };

  const buttonStyle: React.CSSProperties = {
    border: '1px solid #d1d5db', // Added border
    background: 'none',
    padding: '8px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '60px',
    borderRadius: '4px',
    transition: 'background-color 0.2s, border-color 0.2s', // Added border transition
  };

  if (!isExpanded) {
    return (
      <button
        onClick={() => setIsExpanded(true)}
        style={{
          ...buttonStyle,
          flexDirection: 'row',
          justifyContent: 'flex-start',
          backgroundColor: '#f3f4f6',
          padding: '8px 12px',
        }}
        title={selectedIcon.label}
      >
        <SelectedIconComponent size={24} />
        <span style={{ marginLeft: '8px', fontSize: '14px' }}>{selectedIcon.label}</span>
      </button>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <input
        type="text"
        placeholder="Search icons..."
        value={search}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearch(e.target.value);
          setCurrentPage(1);
        }}
        style={{
          width: '100%',
          padding: '8px 12px',
          border: '1px solid #d1d5db',
          borderRadius: '4px',
          fontSize: '14px',
        }}
      />
      <div style={{ 
        display: 'grid', 
        gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))', 
        gap: '8px',
        minHeight: '400px',
      }}>
        {paginatedIcons.map((icon) => {
          const Icon = icon.icon;
          return (
            <button
              key={icon.value}
              onClick={() => handleIconSelect(icon.value)}
              style={{
                ...buttonStyle,
                backgroundColor: value === icon.value ? '#bfdbfe' : '#f3f4f6',
              }}
              title={icon.label}
              onMouseEnter={(e) => e.currentTarget.style.borderColor = '#3b82f6'} // Mouseover effect
              onMouseLeave={(e) => e.currentTarget.style.borderColor = '#d1d5db'} // Reset border color
            >
              <Icon size={24} />
              <span style={{ 
                fontSize: '12px', 
                marginTop: '4px', 
                overflow: 'hidden', 
                textOverflow: 'ellipsis', 
                whiteSpace: 'nowrap', 
                width: '100%', 
                textAlign: 'center' 
              }}>
                {icon.label}
              </span>
            </button>
          );
        })}
      </div>
      {totalPages > 1 && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            style={{
              ...buttonStyle,
              //padding: '4px 12px',
              backgroundColor: '#e5e7eb',
              opacity: currentPage === 1 ? 0.5 : 1,
            }}
          >
            Previous
          </button>
          <span style={{ fontSize: '14px', width: '300px', textAlign: 'center' }}>Page {currentPage} of {totalPages}</span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            style={{
              ...buttonStyle,
              //padding: '4px 12px',
              backgroundColor: '#e5e7eb',
              opacity: currentPage === totalPages ? 0.5 : 1,
            }}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default IconPicker;