import React, { useState, useEffect  } from 'react';
import { ChevronDoubleLeft16Regular, ChevronDoubleRight16Regular  } from '@fluentui/react-icons';
import { useAppSelector, useAppDispatch  } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
//import { setShowDocSettings } from '../..//redux/features/app/appSlice';

import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { setDocSettingsOpen } from '../../redux/features/app/appSlice';
import { useMsal } from '@azure/msal-react';
import { AuthHelper } from '../../libs/auth/AuthHelper'; 
//import { Document16Regular, Folder16Regular } from '@fluentui/react-icons';
//import { Document16Regular } from '@fluentui/react-icons';
//import nodesData from './nodes.json'; // test data


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faUser, faCog } from '@fortawesome/free-solid-svg-icons';

// library.add(faUser, faCog); // Add icons to the library


interface DocSearchSettingsProps {
    onClose: () => void;
}

interface Node {
    value: string;
    label: string;
    children?: Node[];
}

export var checkedNodesGlobal = "";

export const setcheckedNodesGlobal = (nodelist: string, totalCheckboxes: number) => {
    const numCheckedItems = nodelist ? nodelist.split(",").filter(item => item.trim() !== "").length : 0;
    
    // if(numCheckedItems > 700) {
    //     //alert(`Number of checked items: ${numCheckedItems}\nTotal number of checkboxes: ${totalCheckboxes}`);
    //     alert(`You have selected ${numCheckedItems} documents to search.\nThe maximum number of documents you can search on is 700.\nPlease select less documents.`);
    //     return;
    // }
    
    
    if(numCheckedItems == totalCheckboxes)
    {
        nodelist = "";
    }

    checkedNodesGlobal = nodelist;
}

export const DocSearchSettings: React.FC<DocSearchSettingsProps> = ({ onClose }) => {
    const dispatch = useAppDispatch();
    const { showDocSettings } = useAppSelector((state: RootState) => state.app);
    const { docSettingsWidth } = useAppSelector((state: RootState) => state.app);
    const { docSettingsOpen } = useAppSelector((state: RootState) => state.app);
    //const dispatch = useAppDispatch();
    
    // const [checked, setChecked] = useState<string[]>([]); // State for checked items



    const [expanded, setExpanded] = useState<string[]>([]); // State for expanded items
    const [nodes, setNodes] = useState<Node[]>([]);
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    
    const [checked, setChecked] = useState<string[]>(() => {
        // Load checked items from localStorage based on selectedId
        const savedCheckedItems = localStorage.getItem(`checkedItems_${selectedId}`);
        return savedCheckedItems ? JSON.parse(savedCheckedItems) : [];
    });    

    const [totalCheckboxes, setTotalCheckboxes] = useState<number>(0);

    const { instance, inProgress } = useMsal();

    const account = instance.getActiveAccount();
    const username = account?.username;
    //const userid = account?.name;    

    // Function to compute the total number of checkboxes
    const computeTotalCheckboxes = (nodes: Node[]): number => {
        let total = 0;
        const flattenNodes = (nodes: Node[]) => {
            nodes.forEach((node) => {
                if (!node.children || node.children.length === 0) {
                    total++;
                }
                if (node.children && node.children.length > 0) {
                    flattenNodes(node.children);
                }
            });
        };
        flattenNodes(nodes);
        return total;
    };
    
    // Load the total number of checkboxes when nodes are fetched
    useEffect(() => {
        setTotalCheckboxes(computeTotalCheckboxes(nodes));
    }, [nodes]);

    //const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(true);

    // Load the checked items from localStorage if they exist
    useEffect(() => {
        const savedCheckedItems = localStorage.getItem(`checkedItems_${selectedId}`);
        //alert(savedCheckedItems);
        if (savedCheckedItems) {
            setChecked(JSON.parse(savedCheckedItems));
        } else {
            setChecked([]); // Set checked to an empty array if savedCheckedItems is empty
        }
    }, [selectedId]);

    useEffect(() => {
        // Save checked items to localStorage when 'checked' state changes
        //alert(checked);
        localStorage.setItem(`checkedItems_${selectedId}`, JSON.stringify(checked));
    }, [checked, selectedId]);    

    // const nodes2 = [{
    //     value: 'mars',
    //     label: 'Mars',
    //     children: [
    //         { value: 'phobos', label: 'Phobos' },
    //         { value: 'deimos', label: 'Deimos' },
    //     ],
    // }];

    useEffect(() => {
        //alert(selectedId);
        const fetchData = async () => {
            try {
                const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress); // Get access token
                const response = await fetch(process.env.REACT_APP_BACKEND_URI + 'getMetadataCollections/' + username, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`, // Add Authorization header
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                const nodesData = data.nodes || []; // Extracting the nodes array
                
                // FOR TESTING
                // console.log(nodesData);
                // console.log("USERNAME: " + username);
                
                setNodes(nodesData);
                //setNodes(nodes2);

                // // Initialize checked state with all checkbox values
                // const allCheckboxValues = getAllCheckboxValues(nodesData);
                // setChecked(allCheckboxValues);                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []);
    

    // const getAllCheckboxValues = (nodes: Node[]): string[] => {
    //     const values: string[] = [];
    //     const traverseNodes = (node: Node) => {
    //         if (node.children && node.children.length > 0) {
    //             node.children.forEach(childNode => traverseNodes(childNode));
    //         } else {
    //             values.push(node.value);
    //         }
    //     };
    //     nodes.forEach(node => traverseNodes(node));
    //     return values;
    // };

    

    const handleOnClose = () => {
        //dispatch(setShowDocSettings(false)); // Set showFileViewer to false
        // setHidden(true); // Set the component to be hidden
        onClose(); // Call the onClose function
        const divElement = document.getElementById("docsettings_main_div") as HTMLDivElement | null;

        if (divElement) {
            if (divElement.style.width === "10px") {
                divElement.style.width = "400px"; 
                divElement.style.minWidth = "400px"; 
                //setIsSettingsOpen(true);
                dispatch(setDocSettingsOpen(true));
                const contentElement = divElement.querySelector('.settings-content') as HTMLElement | null;
                if (contentElement) {
                    contentElement.style.display = "block";
                }                
            } else {
                divElement.style.width = "10px"; 
                divElement.style.minWidth = "10px"; 
                //setIsSettingsOpen(false);
                dispatch(setDocSettingsOpen(false));
                const contentElement = divElement.querySelector('.settings-content') as HTMLElement | null;
                if (contentElement) {
                    contentElement.style.display = "none";
                }
            }
        }

        
    }

    const toggleSettingsIcon = () => {
        //if(isSettingsOpen == true) 
        if(docSettingsOpen == true)
        {
            return <ChevronDoubleLeft16Regular
            aria-label="dismiss message"
            onClick={handleOnClose}
            color="black"
            style={{float:'right', cursor: 'pointer'}}
            />
        }
        else
        {
            return <ChevronDoubleRight16Regular
            aria-label="dismiss message"
            onClick={handleOnClose}
            color="black"
            style={{float:'right', cursor: 'pointer'}}
            />
        } 
             
    }
        

    // Define nodes for CheckboxTree
    //const nodes = nodesData;

    const settingsStyle = {
        //width: isSettingsOpen ? (docSettingsWidth as string) : '10px',
        maxWidth: docSettingsOpen ? (docSettingsWidth as string) : '10px',
        minWidth: docSettingsOpen ? (docSettingsWidth as string) : '10px',
        paddingLeft: '16px',
        paddingTop: '8px',
        paddingRight: '0px',
        border: '1px solid rgb(204, 204, 204)',
        overflow: 'scroll',
        //transition: 'width 0.5s ease', 
        transition: 'max-width 0.3s ease , min-width 0.3s ease',
        height: '100%',
    };
    
    // const handleShowCheckedItems = () => {
    //     alert("Checked Items: " + checked.join(", "));
    // }

    const handleCheck = (checked: string[]) => {
        setChecked(checked);
        var checkedList = checked.join(", ");

        let totalCheckboxes = 0;
        const flattenNodes = (nodes: Node[]) => {
            nodes.forEach((node) => {
                if (!node.children || node.children.length === 0) {
                    totalCheckboxes++;
                }
                if (node.children && node.children.length > 0) {
                    flattenNodes(node.children);
                }
            });
        };
        flattenNodes(nodes);

        setcheckedNodesGlobal(checkedList, totalCheckboxes);
        //alert("Checked Items: " + checked.join(", "));
    }

    // const icons = {
    //     //check: <Folder16Regular />, // Icon for folder nodes
    //     //uncheck: <Folder16Regular />, // Icon for folder nodes
    //     //halfCheck: <Folder16Regular />, // Icon for folder nodes
    //     //parentClose: <Folder16Regular />, // Icon for closed folder nodes
    //     //parentOpen: <Folder16Regular />, // Icon for open folder nodes
    //     leaf: <Document16Regular />, // Icon for leaf nodes (files)
    //   };
  
    return (<>
        {/* showFileViewer:{showFileViewer}<br/>
        fileViewerPath:{fileViewerPath}<br/> */}
          {showDocSettings && (
            <div id="docsettings_main_div" style={settingsStyle}>
                {toggleSettingsIcon()}
                <div className="settings-content">
                    
                <h3 style={{marginBottom:'0'}}>Document Search ({checked.length === totalCheckboxes || checked.length === 0 ? 'ALL' : checked.length})</h3>
                <div id="warning" style={{ color: 'red', fontSize:'9pt', visibility: checked.length > 700 && checked.length != totalCheckboxes ? 'visible' : 'hidden' }}>
                    WARNING: Max documents search is 700. Please select fewer documents.
                </div>
                    
                    <CheckboxTree
                        nodes={nodes}
                        checked={checked}
                        expanded={expanded}
                        onCheck={handleCheck}
                        onExpand={expanded => setExpanded(expanded)} 
                        //iconsClass="fa5"
                        //icons={icons}
                        //checkModel="all"
                    
                    />
                    {/* <br></br><button onClick={handleShowCheckedItems}>Show Checked Items</button> */}
                </div>
            </div>)}
        </>);

};

export default DocSearchSettings;
