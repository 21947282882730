import {
    Button,
    makeStyles, 
    // Persona,  // Commented out
    Popover,
    PopoverSurface,
    PopoverTrigger,
    shorthands,
    Text,
} from '@fluentui/react-components';
import { FC } from 'react';
import { Constants } from '../../../Constants';
import { useAppDispatch } from '../../../redux/app/hooks';
import { store } from '../../../redux/app/store'; // Import the store
import { setSelectedConversation, deleteConversation } from '../../../redux/features/conversations/conversationsSlice';
import { Breakpoints } from '../../../styles';
import { timestampToDateString } from '../../utils/TextUtils';
//import { DismissCircle24Regular } from '@fluentui/react-icons';

import { useMsal } from '@azure/msal-react';
import { AuthHelper } from '../../../libs/auth/AuthHelper';
import { ChatService } from '../../../libs/services/ChatService';
import { useState } from 'react';
import { DeleteRegular } from '@fluentui/react-icons';
//import { useChat } from '../../../libs/useChat';

//import { useChat } from '../../../libs/useChat';

const chatService = new ChatService(process.env.REACT_APP_BACKEND_URI as string);

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '0.4rem',  // Reduced from 0.8rem
        paddingBottom: '0.4rem',  // Reduced from 0.8rem
        paddingRight: '1rem',
        width: '93%',
        ...Breakpoints.small({
            justifyContent: 'center',
        }),
        // ...shorthands.borderTop('1px', 'solid', 'silver'),
        // ...shorthands.borderBottom('1px', 'solid', 'silver'),
        //...shorthands.borderRadius('8px'),        
    },
    avatar: {
        flexShrink: '0',
        minWidth: '3.2rem',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '0',
        flexGrow: '1',
        lineHeight: '1.2rem',  // Reduced from 1.6rem
        paddingLeft: '0.8rem',
        ...Breakpoints.small({
            display: 'none',
        }),
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        maxHeight: '1rem',  // Reduced from 1.2rem
        lineHeight: '16px',  // Reduced from 20px
        flexGrow: '1',
        justifyContent: 'space-between',
        paddingTop: '4px',  // Reduced from 11px
        paddingRight: '20px'
    },
    timestamp: {
        flexShrink: 0,
        fontSize: 'small',
        maxWidth: '6rem',
        marginTop: '0',
        marginBottom: 'auto',
        marginLeft: '0.8rem',
    },
    title: {
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        minWidth: '4rem',
        minHeight: '1.2rem',
    },
    preview: {
        marginTop: '0.1rem',  // Reduced from 0.2rem
        lineHeight: '14px',  // Reduced from 16px
    },
    previewText: {
        display: 'block',
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    popoverSurface: {
        display: 'none',
        ...Breakpoints.small({
            display: 'flex',
            flexDirection: 'column',
        }),
    },
    hidden: {
        display: 'none',
    },
});

const stripMarkdown = (text: string) => {
    return text
        .replace(/(\*\*|__)(.*?)\1/g, '$2') // Bold
        .replace(/(\*|_)(.*?)\1/g, '$2') // Italics
        .replace(/~~(.*?)~~/g, '$1') // Strikethrough
        .replace(/`(.*?)`/g, '$1') // Inline code
        .replace(/!\[.*?\]\(.*?\)/g, '') // Images
        .replace(/\[.*?\]\(.*?\)/g, '') // Links
        .replace(/####\s+/g, '') // Headers
        .replace(/###\s+/g, '') // Headers
        .replace(/##\s+/g, '') // Headers
        .replace(/#\s+/g, '') // Headers
        .trim(); // Remove extra whitespace
};

interface IChatListItemProps {
    id: string;
    header: string;
    timestamp: number;
    preview: string;
    // botProfilePicture: string;  // Commented out
    isSelected: boolean;
    isExploreKPTsActive: boolean; // Add this prop
    setIsExploreKPTsActive: (active: boolean) => void; // Add this prop
}

export const ChatListItem: FC<IChatListItemProps & { onChatItemClicked: () => void }> = ({
    id,
    timestamp,
    preview,
    isSelected,
    onChatItemClicked,
    isExploreKPTsActive, // Destructure the prop here
}) => {

    //console.log(`Rendering ChatListItem with preview: ${preview}`); // Log the header prop
    //const chat = useChat();
    const classes = useClasses();
    const dispatch = useAppDispatch();

    const onClick = (_ev: any) => {
        dispatch(setSelectedConversation(id));
        window.sessionStorage.setItem("SelectedChatID", id);
        onChatItemClicked();
    };

    const time = timestampToDateString(timestamp);
    
    const { instance, inProgress } = useMsal();
    
    //const chat = useChat();

    const deleteChatAsync = async (id:string) => {
        
        await chatService.deleteChatAsync(id, await AuthHelper.getSKaaSAccessToken(instance, inProgress))
        dispatch(deleteConversation(id));

        const state = store.getState();
        
        if (Object.keys(state.conversations.conversations).length === 0) {
            //window.sessionStorage.setItem("deleteChat", "true");
            window.location.reload();
            //alert();
            //await chat.createChat();
            //await chat.loadChats();
        }        
        //window.sessionStorage.setItem("deleteChat", "true");
        //window.location.reload();
        //chat.loadChats();
    };
 
    // const onDeleteChat = (id:string) => {
        
    //     //alert(id);
    //     chat.deleteChat(id);
    //     //chat.loadChats();
    //     window.location.reload();
        
    // };
    
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };    
    
    return (
        
        <Popover
            openOnHover={!isSelected}
            mouseLeaveDelay={0}
            positioning={{
                position: 'after',
                autoSize: 'width',
            }}
        >
            
            <PopoverTrigger disableButtonEnhancement>
                <div 
                    style={{ 
                        marginLeft:"-24px", 
                        width:"105%", 
                        height: '30px',
                        backgroundColor: isHovered ? '#E0E0E0' : (isExploreKPTsActive ? '#F0F0F0' : 'transparent')
                    }} 
                    className={`${classes.root} ${!preview ? classes.hidden : ''}`} 
                    onClick={onClick} 
                    onMouseEnter={handleMouseEnter} 
                    onMouseLeave={handleMouseLeave}
                >
                    <div>
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    {isHovered && (
                                    
                                        <Button
                                        icon={<DeleteRegular />}
                                        appearance="transparent"
                                        onClick={() => {
                                            //alert(id);
                                            deleteChatAsync(id);                                    
                                            //deleteChat();
                                                                            
                                        }}
                                        style={{position:'absolute', right:'0px', top:'5px'}}
                                    />                            
                                    
                                    )}
                                </td>
                                {/* Commented out Persona avatar
                                <td>
                                    <div><Persona avatar={{ image: { src: botProfilePicture } }} /></div>
                                </td>
                                */}
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={classes.body}>
                        <div className={classes.header}>
                            <Text 
                                className={classes.title} 
                                style={{ color: 'var(--colorNeutralForeground1)' }}
                                title={stripMarkdown(preview ? preview : 'New Chat')}
                            >
                            {stripMarkdown(preview ? preview : 'New Chat')}
                            {/* {header} */}
                            </Text>
                            {/* <Text className={classes.timestamp} size={300}>
                                {time}
                            </Text> */}
                        </div>
                        {/* {preview && (
                            <div className={classes.preview}>
                                {
                                    <Text id={`message-preview-${id}`} size={200} className={classes.previewText}>
                                        {preview}
                                    </Text>
                                }
                            </div>
                        )} */}
                    </div>
                </div>
            </PopoverTrigger>
            
            <PopoverSurface className={classes.popoverSurface}>
                <Text weight="bold">{Constants.bot.profile.fullName}</Text>
                <Text>{time}</Text>
            </PopoverSurface>
        </Popover>
    );
};
