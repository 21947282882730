import React from 'react';
import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { SavedPrompt } from './SavedPrompt';

const useStyles = makeStyles({
    buttonContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        ...shorthands.gap(tokens.spacingHorizontalM),
    },
});

interface SavedPromptData {
    id: string;
    title: string;
    shortDescription: string;
    longDescription: string;
    iconText: string;
    createdBy: string;
    createdByID: string | null;
    systemPrompt: string;
    isPublicYN: boolean,
    promptType: string;
    // Add other fields as needed
}

interface SavedPromptListProps {
    prompts: SavedPromptData[];
    onPromptClick: (promptId: string) => void;
    isEditMode: boolean;
    onEditPrompt: (prompt: SavedPromptData) => void;
    onDeletePrompt: (id: string, title: string) => void;
}

export const SavedPromptList: React.FC<SavedPromptListProps> = ({ prompts, onPromptClick, isEditMode, onEditPrompt, onDeletePrompt }) => {
    const classes = useStyles();

    // Sort the prompts array
    const sortedPrompts = [...prompts].sort((a, b) => {
        // Sort by promptType descending
        if (a.promptType > b.promptType) return 1;
        if (a.promptType < b.promptType) return -1;

        // Sort by isPublicYN with true first
        if (a.isPublicYN && !b.isPublicYN) return -1;
        if (!a.isPublicYN && b.isPublicYN) return 1;

        // Sort alphabetically by title
        return a.title.localeCompare(b.title);
    });

    return (
        <div className={classes.buttonContainer}>
            {sortedPrompts.length === 0 ? (
                <div>Could not load KPTs</div>
            ) : (
                sortedPrompts.map((prompt) => {
                    return (
                        <SavedPrompt
                            key={prompt.id}
                            promptId={prompt.id}
                            title={prompt.title}
                            description={prompt.shortDescription}
                            icon={prompt.iconText}
                            onClick={() => onPromptClick(prompt.id)}
                            isEditMode={isEditMode}
                            onEdit={() => onEditPrompt(prompt)}
                            onDelete={() => onDeletePrompt(prompt.id, prompt.title)}
                            isPublic={prompt.isPublicYN} // Pass the isPublic prop
                        />
                    );
                })
            )}
        </div>
    );
};