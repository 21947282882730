import React from 'react';
import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { EditRegular, DeleteRegular } from '@fluentui/react-icons';
import * as LucideIcons from 'lucide-react';

const useStyles = makeStyles({
    button: {
        width: '300px',
        height: '60px',
        ...shorthands.padding(tokens.spacingVerticalM),
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStroke1),
        ...shorthands.borderRadius('8px'),
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative', // Added to make child absolute positioning relative to this container
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground3Selected,
        },
    },
    buttonNoHover: {
        width: '300px',
        height: '60px',
        ...shorthands.padding(tokens.spacingVerticalM),
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStroke1),
        ...shorthands.borderRadius('8px'),
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative', // Added to make child absolute positioning relative to this container
    },
    buttonPrivate: {
        width: '300px',
        height: '60px',
        ...shorthands.padding(tokens.spacingVerticalM),
        backgroundColor: '#f7fbfd',
        ...shorthands.border('2px', 'solid', tokens.colorBrandBackground),
        ...shorthands.borderRadius('8px'),
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative', // Added to make child absolute positioning relative to this container
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground3Selected,
        },
    },    
    icon: {
        marginRight: tokens.spacingHorizontalM,
        fontSize: '24px', // Adjust size as needed
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonTitle: {
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightBold,
        fontFamily: 'Segoe UI',
        marginBottom: tokens.spacingVerticalXS,
        textAlign: 'left'
    },
    buttonDescription: {
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground2,
        textAlign: 'left',
        fontFamily: 'Segoe UI',
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2, // Reduced to 2 lines to accommodate icon
        WebkitBoxOrient: 'vertical',
    },
    editButtons: {
        display: 'flex',
        position: 'absolute',
        top: '5px',
        right: '5px',
    },
    iconButton: {
        backgroundColor: 'transparent',
        ...shorthands.border('none'),
        cursor: 'pointer',
        ...shorthands.padding('2px'),
        marginLeft: '5px',
        fontSize: '16px', // Reduced font size for smaller buttons
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground3Hover,
        },
    },
});

interface SavedPromptProps {
    promptId: string;
    title: string;
    description: string;
    icon: string;
    onClick: () => void;
    isEditMode: boolean;
    onEdit: () => void;
    onDelete: () => void;
    isPublic: boolean; // Added new prop
}

export const SavedPrompt: React.FC<SavedPromptProps> = ({ title, description, icon, onClick, isEditMode, onEdit, onDelete, isPublic }) => {
    const classes = useStyles();

    // Determine the border style based on isPublic
    const buttonClass = isPublic ? classes.button : classes.buttonPrivate; // Adjust class based on isPublic

    const getIconComponent = (iconName: string): React.ElementType => {
        // Convert dash-separated name to PascalCase
        const pascalCaseName = iconName.split('-')
            .map(part => part.charAt(0).toUpperCase() + part.slice(1))
            .join('') + 'Icon';
    
        // Check if the icon exists in LucideIcons
        if (pascalCaseName in LucideIcons) {
            return (LucideIcons as any)[pascalCaseName];
        }
    
        // If not found, try without 'Icon' suffix
        const alternativeName = pascalCaseName.slice(0, -4);
        if (alternativeName in LucideIcons) {
            return (LucideIcons as any)[alternativeName];
        }
    
        // If still not found, return default icon
        //console.log(`Icon not found for: ${iconName}. Using default FileIcon.`);
        return LucideIcons.FileIcon;
    };

    const IconComponent = getIconComponent(icon);

    const handleEdit = (e: React.MouseEvent) => {
        e.stopPropagation();
        onEdit();
    };

    const handleDelete = (e: React.MouseEvent) => {
        e.stopPropagation();
        onDelete();
    };

    const handleClick = (e: React.MouseEvent) => {
        if (isEditMode) {
            handleEdit(e); // Call handleEdit when in edit mode
        } else {
            onClick(); // Call onClick when not in edit mode
        }
    };    

    return (
        //console.log(icon),
        <div className={isEditMode ? classes.buttonNoHover : buttonClass} onClick={handleClick}>
            <span className={classes.icon}>
                {IconComponent && <IconComponent size={24} />}
            </span>
            <div className={classes.content}>
                <span className={classes.buttonTitle}>{title}</span>
                <span className={classes.buttonDescription}>{description}</span>
            </div>
            {isEditMode && (
                <div className={classes.editButtons}>
                    <button style={{border:'none', display:'none'}} className={classes.iconButton} onClick={handleEdit}>
                        <EditRegular />
                    </button>
                    <button style={{border:'none'}} className={classes.iconButton} onClick={handleDelete}>
                        <DeleteRegular />
                    </button>
                </div>
            )}
        </div>
    );
};