import React, { useState, useEffect } from 'react';
import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { SavedPromptData } from './SavedPromptExplore';
import { useChat } from '../../libs/useChat';
import IconPicker from './IconPicker';
import { useMsal } from '@azure/msal-react'; // Import useMsal to get the logged-in user

const useStyles = makeStyles({
  container: {
    width: '100%',
    maxWidth: '1200px',
    ...shorthands.margin('0', 'auto'),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    //gap: '20px',
    ...shorthands.padding('20px'),
    width: '100%', // Make the form take full width of its container
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  label: {
    fontWeight: 'bold',
  },
  input: {
    ...shorthands.padding('8px'),
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
    ...shorthands.borderRadius('4px'),
    fontSize: '14px',
    fontFamily: 'Segoe UI', // Added font family
  },
  textarea: {
    ...shorthands.padding('8px'),
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
    ...shorthands.borderRadius('4px'),
    fontSize: '14px',
    fontFamily: 'Segoe UI', // Added font family
    minHeight: '100px',
  },
  select: {
    ...shorthands.padding('8px'),
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
    ...shorthands.borderRadius('4px'),
    fontSize: '14px',
    fontFamily: 'Segoe UI', // Added font family
    backgroundColor: tokens.colorNeutralBackground1,
  },
  checkbox: {
    marginRight: '8px',
  },
  error: {
    color: tokens.colorPaletteRedForeground1,
    marginTop: '10px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    //gap: '10px',
  },
  button: {
    ...shorthands.padding('8px', '16px'),
    ...shorthands.borderRadius('8px'),
    fontSize: '14px',
    cursor: 'pointer',
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
    '&:hover': {
      backgroundColor: tokens.colorBrandBackgroundHover,
    },
  },
  primaryButton: {
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralForegroundOnBrand,
    ...shorthands.border('none'),
    '&:hover': {
      backgroundColor: tokens.colorBrandBackgroundHover,
    },
  },
  cancelButton: {
    marginRight: '8px',
    ...shorthands.padding('8px', '16px'),
    ...shorthands.borderRadius('8px'),
    fontSize: '14px',
    cursor: 'pointer',
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
    '&:hover': {
      backgroundColor: tokens.colorNeutralBackground2, // Light gray hover color
    },
  },
  
});

// const iconOptions = [
//     { value: 'hourglass', label: '🔍 Hourglass' },
//     { value: 'document', label: '📄 Document' },
//     { value: 'hand_writing', label: '✍️ Hand Writing' },
//     { value: 'document_pencil', label: '📝 Document Pencil' },
//     { value: 'lightbulb', label: '💡 Lightbulb' },
// ];

interface SavedPromptFormProps {
    savedPrompt?: SavedPromptData | null; // Updated to allow null
    onSave: (savedPrompt: SavedPromptData) => void;
    onCancel: () => void;
}

export const SavedPromptForm: React.FC<SavedPromptFormProps> = ({ savedPrompt, onSave, onCancel }) => {
    const styles = useStyles();
    const { addSavedPrompt, editSavedPrompt } = useChat();
    const { instance } = useMsal(); // Get the MSAL instance
    const account = instance.getActiveAccount(); // Get the active account
    const userId: string | null = account?.username || null; // Ensure userId is string or null

    const [formData, setFormData] = useState<SavedPromptData>({
        id: '',
        title: '',
        createdBy: '',
        createdByID: null, // Added createdByID to formData
        shortDescription: '',
        longDescription: '',
        iconText: 'document',
        promptType: 'user_documents',
        isPublicYN: false,
        systemPrompt: '',
    });

    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (savedPrompt) {
            setFormData(savedPrompt);
            adjustTextareaHeight(); // Adjust height when loading savedPrompt
        } else {
            // Reset form data if in create mode
            setFormData({
                id: '',
                title: '',
                createdBy: '',
                createdByID: null, // Reset createdByID to null
                shortDescription: '',
                longDescription: '',
                iconText: 'document',
                promptType: 'user_documents',
                isPublicYN: false,
                systemPrompt: '',
            });
        }
    }, [savedPrompt]);

    const adjustTextareaHeight = () => {
        const longDescriptionTextarea = document.getElementById('longDescription') as HTMLTextAreaElement;
        const systemPromptTextarea = document.getElementById('systemPrompt') as HTMLTextAreaElement;

        if (longDescriptionTextarea) {
            longDescriptionTextarea.style.height = 'auto'; // Reset height
            longDescriptionTextarea.style.height = `${longDescriptionTextarea.scrollHeight}px`; // Set to scroll height
        }

        if (systemPromptTextarea) {
            systemPromptTextarea.style.height = 'auto'; // Reset height
            systemPromptTextarea.style.height = `${systemPromptTextarea.scrollHeight}px`; // Set to scroll height
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData(prev => ({ ...prev, isPublicYN: e.target.checked }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError(null);

        try {
            let result;
            const updatedFormData = { ...formData, createdByID: userId }; // Add userId to formData
            if (formData.id) {
                result = await editSavedPrompt(updatedFormData);
            } else {
                result = await addSavedPrompt(updatedFormData);
            }
            onSave(result);
        } catch (err: any) {
            setError(err.message);
        }
    };

    return (
        <div className={styles.container}>
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.field}>
                    <label htmlFor="title" className={styles.label}>KPT Title</label>
                    <input type="text" id="title" name="title" value={formData.title} onChange={handleInputChange} required className={styles.input} />
                </div>
                <div className={styles.field}>
                    <label htmlFor="createdBy" className={styles.label}>Created By</label>
                    <input type="text" id="createdBy" name="createdBy" value={formData.createdBy} onChange={handleInputChange} required className={styles.input} />
                </div>
                <div className={styles.field}>
                    <label htmlFor="shortDescription" className={styles.label}>Short Description</label>
                    <input type="text" id="shortDescription" name="shortDescription" value={formData.shortDescription} onChange={handleInputChange} required className={styles.input} />
                </div>
                <div className={styles.field}>
                    <label htmlFor="longDescription" className={styles.label}>Long Description</label>
                    <textarea id="longDescription" name="longDescription" value={formData.longDescription} onChange={handleInputChange} className={styles.textarea} />
                </div>
                <div className={styles.field}>
                    <label htmlFor="iconText" className={styles.label}>Icon</label>
                    <IconPicker
                        value={formData.iconText}
                        onChange={(value: string) => setFormData(prev => ({ ...prev, iconText: value }))}
                    />
                </div>                
                <div className={styles.field}>
                    <label className={styles.label}>
                        <input
                            type="checkbox"
                            name="isPublicYN"
                            checked={formData.isPublicYN}
                            onChange={handleCheckboxChange}
                            className={styles.checkbox}
                        />
                        Public
                    </label>
                </div>
                <div className={styles.field}>
                    <label htmlFor="systemPrompt" className={styles.label}>System Prompt</label>
                    <textarea id="systemPrompt" name="systemPrompt" value={formData.systemPrompt} onChange={handleInputChange} required className={styles.textarea} />
                </div>
                {error && <div className={styles.error}>{error}</div>}
                <div className={styles.buttonContainer}>
                    <button type="button" onClick={onCancel} className={`${styles.button} ${styles.cancelButton}`}>Cancel</button>
                    <button type="submit" className={`${styles.button} ${styles.primaryButton}`}>{formData.id ? 'Update' : 'Create'}</button>
                </div>
            </form>
        </div>
    );
};

export default SavedPromptForm;