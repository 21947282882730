import React, { useState } from 'react';
import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

const useStyles = makeStyles({
    modal: {
        display: 'block',
        position: 'fixed',
        zIndex: 1,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        ...shorthands.overflow('auto'),
        backgroundColor: 'rgba(0,0,0,0.4)',
    },
    modalContent: {
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.margin('5%', 'auto'),
        ...shorthands.padding(tokens.spacingVerticalM),
        ...shorthands.border('1px', 'solid', '#888'),
        width: '80%',
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        maxHeight: '80%',
        overflowY: 'auto',
        height: '80vh', // Fixed height for the modal content
    },
    close: {
        color: '#aaa',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    jsonText: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        fontFamily: 'monospace',
        paddingLeft: '50px',
        paddingRight: '50px',
    },
    documentList: {
        listStyleType: 'none',
    },
    documentItem: {
        marginBottom: '10px',
    },
    button: {
        marginLeft: '10px',
        cursor: 'pointer',
        width: '16px',
        display: 'inline-block', 
        textAlign: 'center', 
    },
    icon: {
        fontSize: '20px',
        marginLeft: '10px',
        cursor: 'pointer',
    },
    link: {
        fontSize: '16px',
        color: 'inherit',
        cursor: 'pointer',
        backgroundColor: 'transparent',
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        //justifyContent: 'space-between',
        marginBottom: '20px',
        paddingLeft: '20px',
    },
    searchInput: {
        marginLeft: '50px',
        marginTop: '5px',
        ...shorthands.padding('5px'),
    },
});

interface Document {
    name: string;
    link: string;
    content: string;
}

interface ChatHistoryItemViewDocumentsProps {
    chatItem: any;
    onClose: () => void;
}

const parseDocuments = (prompt: string): Document[] => {
    //console.log(prompt);
    const sourcesIndex = prompt.indexOf('Sources:');
    if (sourcesIndex === -1) return [];

    const sourcesText = prompt.substring(sourcesIndex + 8).trim();
    const documentRegex = /(https:\/\/\S+\/([^/]+\.pdf))[\s\S]*?((?=https:\/\/|\s*$))/g;
    const documents: Document[] = [];
    const uniqueNames = new Set<string>();

    let match;
    while ((match = documentRegex.exec(sourcesText)) !== null) {
        const [fullMatch, link, filename] = match;
        let name;
        try {
            name = decodeURIComponent(filename.replace(/\+/g, ' '));
        } catch (e) {
            console.error(`Failed to decode URI component: ${filename}`, e);
            name = filename; // Fallback to the original filename if decoding fails
        }

        if (!uniqueNames.has(name)) {
            uniqueNames.add(name);
            let content = fullMatch.replace(link, '').trim();
            content = content.replace(/^:\s*/, '');
            documents.push({ name, link, content });
        }
    }

    return documents;
};

const ChatHistoryItemViewDocuments: React.FC<ChatHistoryItemViewDocumentsProps> = ({ chatItem, onClose }) => {
    const classes = useStyles();
    const documents = parseDocuments(chatItem.prompt);
    const [expandedDocs, setExpandedDocs] = useState<Set<string>>(new Set());
    const [searchQuery, setSearchQuery] = useState<string>('');

    const toggleDocument = (link: string) => {
        setExpandedDocs(prev => {
            const newExpandedDocs = new Set(prev);
            if (newExpandedDocs.has(link)) {
                newExpandedDocs.delete(link);
            } else {
                newExpandedDocs.add(link);
            }
            return newExpandedDocs;
        });
    };

    const filteredDocuments = documents.filter(doc => 
        doc.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className={classes.modal}>
            <div className={classes.modalContent}>
                <span className={classes.close} onClick={onClose}>&times;</span>
                <div className={classes.headerContainer}>
                    <h2>Sources ({filteredDocuments.length})</h2>
                    <input 
                        type="text" 
                        placeholder="Search documents..." 
                        value={searchQuery} 
                        onChange={(e) => setSearchQuery(e.target.value)} 
                        className={classes.searchInput}
                    />
                </div>
                {filteredDocuments.length === 0 ? (
                    <p>No documents found.</p>
                ) : (
                    <ul className={classes.documentList}>
                        {filteredDocuments.map((doc) => (
                        <li key={doc.link} className={classes.documentItem}>
                            <span
                                className={classes.button}
                                style={{ 
                                    cursor: 'pointer', 
                                    fontSize: '24px', 
                                    marginRight: '10px' 
                                }}
                                onClick={() => toggleDocument(doc.link)}
                            >
                                {expandedDocs.has(doc.link) ? '-' : '+'}
                            </span>
                            <a 
                                href={doc.link} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className={classes.link}
                            >
                                <strong>{doc.name}</strong>
                            </a>
                            {expandedDocs.has(doc.link) && <pre className={classes.jsonText}>{doc.content}</pre>}
                        </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default ChatHistoryItemViewDocuments;
