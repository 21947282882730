import { Title3 } from '@fluentui/react-components';
import { FC } from 'react';

interface INotAvailableProps {
    text: string;
}

const NotAvailable: FC<INotAvailableProps> = ({ text }) => {
    return (
        <div style={{ padding: 80, gap: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Title3>{text}</Title3>
        </div>
    );
};

export default NotAvailable;