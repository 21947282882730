import React, { useEffect, useState } from 'react';
import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
//import { Edit24Regular, Delete24Regular } from '@fluentui/react-icons';
import { SavedPromptList } from './SavedPromptList';
import { useChat } from '../../libs/useChat'; // Import the useChat hook
import SavedPromptForm from './SavedPromptForm'; // Import the SavedPromptForm
import { useMsal } from '@azure/msal-react'; // Import useMsal to get the logged-in user

const useStyles = makeStyles({
    root: { // Renamed from container to root
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '90vh',
        maxHeight: 'calc(100vh - 200px)', // Adjusted to allow for some padding/margin
        overflowY: 'auto', // Enable vertical scrolling
        backgroundColor: '#f5f5f5', // Match the color with ChatRoom
        color: tokens.colorNeutralForeground1,
        position: 'relative',
        width: '100%',
        ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStroke1), // Match border with ChatRoom
    },
    contentContainer: { // New class for the inner container
        width: '800px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    createButtonContainer: {
        position: 'absolute',
        top: tokens.spacingVerticalM,
        right: tokens.spacingHorizontalM,
        display: 'flex',
        justifyContent: 'space-between', // Ensure space between dropdown and buttons
        
    },
    dropdownContainer: { // New container for the dropdown
        position: 'absolute',
        top: tokens.spacingVerticalM,
        left: tokens.spacingHorizontalM, // Align to the left
    },
    createButton: {
        ...shorthands.padding('8px', '16px'),
        ...shorthands.borderRadius('8px'),
        fontSize: '14px',
        cursor: 'pointer',
        color: tokens.colorNeutralForegroundOnBrand,
        backgroundColor: tokens.colorBrandBackground,
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        '&:hover': {
          backgroundColor: tokens.colorBrandBackgroundHover,
        },
        ...shorthands.transition('background-color', '0.2s'),
        width: '100px', // Set a fixed width for consistency
        marginRight: tokens.spacingHorizontalM, // Add space between buttons
    },
    editButton: {
        ...shorthands.padding('8px', '16px'),
        ...shorthands.borderRadius('8px'),
        fontSize: '14px',
        cursor: 'pointer',
        color: tokens.colorNeutralForegroundOnBrand,
        backgroundColor: tokens.colorBrandBackground,
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        '&:hover': {
            backgroundColor: tokens.colorBrandBackgroundHover,
        },
        ...shorthands.transition('background-color', '0.2s'),
        width: '100px', // Set the same width as createButton
        marginRight: tokens.spacingHorizontalM,
    },  
    closeButton: {
        ...shorthands.padding('8px', '16px'),
        ...shorthands.borderRadius('8px'),
        fontSize: '14px',
        cursor: 'pointer',
        color: tokens.colorNeutralForegroundOnBrand,
        backgroundColor: tokens.colorBrandBackground,
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        '&:hover': {
            backgroundColor: tokens.colorBrandBackgroundHover,
        },
        ...shorthands.transition('background-color', '0.2s'),
        width: '100px', // Set the same width as createButton
    },     

    textCenter: {
        textAlign: 'center',
    },
    title: {
        fontSize: '36pt',
        fontWeight: tokens.fontWeightBold,
        marginBottom: '30px',
        marginTop: '100px',
    },
    description: {
        //maxWidth: '40rem',
        marginBottom: tokens.spacingVerticalXL,
        ...shorthands.padding(0, tokens.spacingHorizontalM),
        fontSize: '12pt',
    },
    searchInput: {
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalM),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        ':focus': {
            ...shorthands.outline('none'),
            boxShadow: `0 0 0 2px ${tokens.colorBrandStroke1}`,
        },
        height: '30px',
        width: '630px'
    },
    inputContainer: {
        width: '100%',
        //maxWidth: '40rem',
        //...shorthands.padding(0, tokens.spacingHorizontalM),
    },
    editMode: { 
        backgroundColor: '#f8f6f1' , 
        ...shorthands.borderTop('2px', 'dashed', tokens.colorBrandBackground),
    },
    notification: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        backgroundColor: '#000', // Highlight color
        color: '#fff',
        ...shorthands.padding('10px'),
        ...shorthands.borderRadius('5px'),
        zIndex: 1000,
        ...shorthands.transition('opacity', '0.5s', 'ease-in-out'),
    },
    fadeOut: {
        opacity: 0,
    },
    dropdown: { // New style for the dropdown
        ...shorthands.padding('8px', '8px'),
        ...shorthands.borderRadius('8px'),
        fontSize: '14px',
        //border: `1px solid ${tokens.colorNeutralStroke1}`,
        //...shorthands.border('1px', 'solid', '#000'),
        cursor: 'pointer',
        backgroundColor: tokens.colorNeutralBackground1,
        width: '130px', // Set a fixed width similar to buttons
        marginLeft: tokens.spacingHorizontalM, // Add space from the left
    },
});

export interface SavedPromptData {
    id: string;
    title: string;
    shortDescription: string;
    longDescription: string;
    iconText: string;
    createdBy: string;
    createdByID: string | null;
    systemPrompt: string;
    isPublicYN: boolean,
    promptType: string;
    // Add any other fields that are part of your saved prompt data
}

export const SavedPromptExplore: React.FC<SavedPromptExploreProps> = ({ onPromptClick, onClose }) => {
    const classes = useStyles();
    const { getAllSavedPrompts, deleteSavedPrompt } = useChat(); // Destructure getAllSavedPrompts from useChat
    const { instance } = useMsal(); // Get the MSAL instance
    const account = instance.getActiveAccount(); // Get the active account
    const userId = account?.username; // Get the logged-in user's ID

    const [savedPrompts, setSavedPrompts] = useState<SavedPromptData[]>([]); // State to hold the prompts
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [isFormVisible, setIsFormVisible] = useState(false); // New state for form visibility
    const [currentPrompt, setCurrentPrompt] = useState<SavedPromptData | null>(null); // State to hold the current prompt for editing
    const [isEditMode, setIsEditMode] = useState(false);
    const [showNotification, setShowNotification] = useState(false); // New state for notification
    const [filterOption, setFilterOption] = useState('All'); // New state for filter option

    const AdminUsers = process.env.REACT_APP_GLOBAL_ADMIN_USERS?.split(',') || [];

    useEffect(() => {
        const fetchSavedPrompts = async () => {
            try {
                const fetchedPrompts = await getAllSavedPrompts();
                setSavedPrompts(fetchedPrompts);
            } catch (error) {
                console.error('Error fetching prompts:', error);
            }
        };

        fetchSavedPrompts();
    }, []); // Fetch prompts on component mount

    useEffect(() => {
        if (isEditMode) {
            if (AdminUsers.includes(userId?.toLowerCase() || '')) {
                setFilterOption('GlobalAdmin'); // Set filter to 'GlobalAdmin' if the user is a Global Admin
            } else {
                setFilterOption('UserCreated'); // Set filter to 'UserCreated' if the user is not a Global Admin
            }
        } else {
            setFilterOption('All'); // Reset to 'All' when exiting edit mode
        }
    }, [isEditMode, userId]);

    const handleCreateClick = () => {
        setCurrentPrompt(null); // Reset current prompt for create mode
        setIsFormVisible(true); // Show the form
    };

    const handleSave = async () => {
        // Logic to handle saving the prompt
        try {
            await getAllSavedPrompts(); // Fetch updated prompts after saving
            const fetchedPrompts = await getAllSavedPrompts();
            setSavedPrompts(fetchedPrompts); // Update state with new prompts
        } catch (error) {
            console.error('Error fetching updated prompts:', error);
        }
        setIsFormVisible(false); // Hide the form after saving
    };

    const handleCancel = () => {
        setIsFormVisible(false); // Hide the form on cancel
    };

    const handleEditClick = () => {
        if (isEditMode) {
            setFilterOption('All'); // Set filter to "All" when exiting edit mode
        } else {
            setShowNotification(true); // Show notification when entering edit mode
            setTimeout(() => {
                setShowNotification(false); // Hide notification after 3 seconds
            }, 2000);
        }
        setIsEditMode(!isEditMode);
    };

    const handleEditPrompt = (prompt: SavedPromptData) => {
        setCurrentPrompt(prompt);
        setIsFormVisible(true);
    };

    const handleDeletePrompt = async (promptId: string, title: string) => {
        const confirmDelete = window.confirm(`Are you sure you want to delete the saved prompt titled "${title}"?`);
        if (!confirmDelete) {
            return; // Exit if the user cancels the deletion
        }

        try {
            await deleteSavedPrompt(promptId);
            // After successful deletion, refresh the prompts list
            const fetchedPrompts = await getAllSavedPrompts();
            setSavedPrompts(fetchedPrompts);
            //dispatch(addAlert({ message: 'Prompt deleted successfully', type: AlertType.Success }));
        } catch (error) {
            console.error('Error deleting prompt:', error);
            //dispatch(addAlert({ message: 'Failed to delete prompt', type: AlertType.Error }));
        }
    };

    // Filter prompts based on search query and filter option
    const filteredPrompts = savedPrompts.filter(prompt => {
        const matchesSearch = prompt.title.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesFilter = 
            (filterOption === 'UserCreated' && prompt.createdByID?.toLowerCase() === userId?.toLowerCase()) || // Show user-created prompts in edit mode
            (filterOption === 'My' && !prompt.isPublicYN && prompt.createdByID?.toLowerCase() === userId?.toLowerCase()) ||
            (filterOption === 'Public' && prompt.isPublicYN) ||
            (filterOption === 'All' && (prompt.isPublicYN || (!prompt.isPublicYN && prompt.createdByID?.toLowerCase() === userId?.toLowerCase()))) ||
            (filterOption === 'GlobalAdmin'); // Show all prompts for Global Admin
        return matchesSearch && matchesFilter;
    });

    return (
        <div className={`${classes.root} ${isEditMode ? classes.editMode : ''}`}>
            {showNotification && (
                <div className={`${classes.notification} ${!showNotification ? classes.fadeOut : ''}`}>
                    Click on a KPT to edit.
                </div>
            )}
            <div className={classes.contentContainer}>
                {!isEditMode && ( // Conditionally render the dropdown
                    <div className={classes.dropdownContainer}>
                        <select
                            value={filterOption}
                            onChange={(e) => setFilterOption(e.target.value)}
                            className={classes.dropdown} // Apply the new dropdown style
                        >
                            <option value="All">All KPTs</option>
                            <option value="Public">Public KPTs</option>
                            <option value="My">My KPTs</option>
                            {AdminUsers.includes(userId?.toLowerCase() || '') && (
                                <option value="GlobalAdmin">Global Admin</option>
                            )}
                        </select>
                    </div>
                )}
                <div className={classes.createButtonContainer}>
                    {!isFormVisible && (
                        <>
                            <button className={classes.createButton} onClick={handleCreateClick}>
                                + Create
                            </button>
                            <button className={classes.editButton} onClick={handleEditClick}>
                                {isEditMode ? 'Done' : 'Edit'}
                            </button>
                            <button className={classes.closeButton} onClick={onClose} style={{display: 'none'}}>
                                Close
                            </button>
                        </>
                    )}
                </div>
                {isFormVisible ? (
                    <SavedPromptForm savedPrompt={currentPrompt} onSave={handleSave} onCancel={handleCancel} />
                ) : (
                    <>
                        <div className={classes.textCenter}>
                            <h1 className={classes.title}>KPTs</h1>
                            {isEditMode && ( // New message when in edit mode
                                <p className={classes.description}>Click on a KPT to edit.</p>
                            )}
                            <p className={`${classes.description} ${classes.textCenter}`}>
                                Discover and create custom versions of Kwery Prompt Templates that combine instructions with extra knowledge to create very powerful Generative AI Tools.
                            </p>
                            <div className={classes.inputContainer}>
                                <input
                                    type="text"
                                    placeholder="Search KPTs"
                                    className={classes.searchInput}
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <br></br>
                                <br></br>
                                <br></br>
                                <SavedPromptList
                                    onPromptClick={onPromptClick}
                                    prompts={filteredPrompts}
                                    isEditMode={isEditMode}
                                    onEditPrompt={handleEditPrompt}
                                    onDeletePrompt={(id: string, title: string) => handleDeletePrompt(id, title)}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

interface SavedPromptExploreProps {
    onPromptClick: (promptId: string) => void;
    onClose: () => void; // Add onClose prop
}